import React from "react";
//import Slider from 'react-slick';
//import Img from "gatsby-image";
import { Link, graphql } from "gatsby";
import Blob from "../components/blob";

import Layout from "../components/layout";

const IndexPage = ({ data }) => (
  <Layout>
    <div>
      <div id="hero">
        <article id="app" />
        <Blob />

        <div className="smiling-bee">
          <img src="https://www.datocms-assets.com/7775/1540098241-bee-smile.png" alt="Zena B" />
        </div>

        <div className="coming-through-bee">
          <img src="https://www.datocms-assets.com/7775/1540094149-bee-coming-through.png" alt="Zena B" />
        </div>

        {(() => {
          if (data.datoCmsHome.soldOut) {
            return (
              <div className="stock sold-out">
                <img src="https://www.datocms-assets.com/10075/1557011945-sold-out.png" alt="Sold Out" />
              </div>
            );
          } else {
            return (
              <div className="stock in-stock">
                <img src="https://www.datocms-assets.com/10075/1557011938-in-stock.png" alt="In Stock" />
              </div>
            );
            //https://react-cn.github.io/react/tips/if-else-in-JSX.html
          }
        })()}

        <div id="intro" className="columns is-full is-gapless">
          <div className="column is-one-fifth-tablet is-hidden-mobile" />
          <div className="column column is-four-fifths-mobile is-two-fifths">
            <div className="logo">
              <Link to="/">
                <img alt="Zena B's Raw Honey" title="Zena B's Raw Honey" src="https://www.datocms-assets.com/7775/1539919614-zena-b-raw-honey-logo.svg" />
              </Link>
            </div>
            <div
              className="introText"
              dangerouslySetInnerHTML={{
                __html: data.datoCmsHome.heroTextNode.childMarkdownRemark.html
              }}
            />
            <button className="button" type="submit">
              {(() => {
                if (data.datoCmsHome.soldOut) {
                  return <Link to="/signup" className="notified">Get notified of new stock</Link>;
                } else {
                  return <Link to="/order" className="request">Request an Order</Link>;
                }
              })()}
            </button>
          </div>
        </div>
      </div>

      <div className="columns is-full is-gapless infoPane-wrapper" id="info">
        <div className="column is-half pic1 shapeShift" />
        {/*}
          <Slider infinite={true} slidesToShow={1} arrows>
            {data.datoCmsHome.gallery.map(({ fluid }) => (
              <img alt={data.datoCmsHome.title} key={fluid.src} src={fluid.src} />
            ))}
          </Slider>
        </div>
        {*/}
        <div className="column is-half">
          <div className="columns is-gapless">
            <div className="column infoPane">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.datoCmsHome.unfilteredTextNode.childMarkdownRemark.html
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns is-full is-gapless infoPane-wrapper">
        <div className="column is-half">
          <div className="columns is-gapless">
            <div className="column infoPane flower">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.datoCmsHome.storageTextNode.childMarkdownRemark.html
                }}
              />
            </div>
          </div>
        </div>

        <div className="column is-half pic2 shapeShift" />
      </div>

      <div className="faq-wrapper" id="FAQs">
        <div className="faq">
          <div className="columns">
            <div className="column">
              <h2 className="script">Frequently Asked Questions</h2>
            </div>
          </div>

          <div className="columns">
            <div className="column is-half">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsHome.faq1Node.childMarkdownRemark.html
                }}
              />
            </div>
            <div className="column is-half">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsHome.faq2Node.childMarkdownRemark.html
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout >
);

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    datoCmsHome {
      soldOut
      heroTextNode {
        childMarkdownRemark {
          html
        }
      }
      unfilteredTextNode {
        childMarkdownRemark {
          html
        }
      }
      picture1 {
        sizes(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      storageTextNode {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
      }
      picture2 {
        sizes(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      faq1Node {
        childMarkdownRemark {
          html
        }
      }
      faq2Node {
        childMarkdownRemark {
          html
        }
      }
    }
    allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          profileType
          url
        }
      }
    }
    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          coverImage {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
