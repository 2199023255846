import React, { Component } from "react";

class Blob extends Component {
  render() {
    return (
      <div>
        <div className="morph-wrap">
          <svg
            className="morph"
            width="1221"
            height="982"
            viewBox="0 0 1221 982">
            <path
              id="blob-bg"
              d="M1017 73.9996C871.336 -74.5286 716 37.9996 687 93.9996C657 124 654 239.5 631.5 311C611.406 374.853 568 372.5 542 350.5C516 328.5 437 305.333 399 298C376.5 288 365.5 266.5 368 246C370.45 225.91 350.333 172 339 148C288.945 41.9996 125.464 -59.8475 36.5001 86.4996C-79 276.5 116 327 181 342C243.578 356.441 175.149 452.863 167 472.5C125.5 572.5 179.5 802 391.5 802C445.14 802 483 828 507 862C522.882 884.5 526.757 907.599 567 934C770.501 1067.5 915.501 890.5 927 786V785.999C927 757.998 927 685.999 1051 650C1093.25 637.732 1255.56 489.986 1213 364C1150.5 179 1195 255.5 1017 73.9996Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="401"
                y1="398"
                x2="959"
                y2="874"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FDB600" />
                <stop offset="1" stopColor="#D29411" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    );
  }
}

export default Blob;
